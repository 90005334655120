import { localeSubPathMap } from '@common/utils/language'
import getContentfulClient from 'utils/contentfulClient'
import userPreferencesHandler from './user-preferences-handler'

async function getSelectedTags(userId: any, authHeader: any) {
  if (!userId || !authHeader) {
    return {
      preferences: {
        tags: []
      }
    }
  }

  return userPreferencesHandler({ userId, authHeader })
}

export default async function getTags(
  {
    id, locale, authHeader
  }
) {
  const limit: number = 50
  const skip: number = 0
  const generatedFor: string = 'Site'

  const externalTagCollection = await getContentfulClient().getEntries({
    skip,
    limit,
    locale: localeSubPathMap.get(locale),
    content_type: 'externalTag',
    'fields.generatedFor': generatedFor,
    select: ['sys', 'fields.name', 'fields.title', 'fields.slug']
  })

  let selectedTags: any
  if (id) {
    selectedTags = await getSelectedTags(id, authHeader)
  } else {
    selectedTags = {
      preferences: {
        tags: []
      }
    }
  }

  const mapSelectedTags = (items: any[]) => items.map((item) => ({
    ...item,
    id: item?.sys?.id,
    name: item?.fields?.name,
    title: item?.fields?.title,
    slug: item?.fields?.slug,
    selected:
      selectedTags?.preferences?.tags?.includes(item.sys.id) || false
  }))

  return {
    ...externalTagCollection,
    items: mapSelectedTags(externalTagCollection.items)
  }
}
