import { createClient } from 'contentful'
import { ContentfulClientApi } from 'contentful/dist/types/types'

const space = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID as string
const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN as string
const previewAccessToken = process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN as string
const environment = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT as string

const host = 'cdn.contentful.com'

interface ClientI {
  preview: boolean
}

let client: ContentfulClientApi<undefined>
let previewClient: ContentfulClientApi<undefined>

export default function getContentfulClient(
  { preview }: ClientI = { preview: false }
): ContentfulClientApi<undefined> {
  if (preview && previewClient) return previewClient
  if (client) return client

  try {
    if (preview) {
      previewClient = createClient(
        {
          space,
          environment,
          host: 'preview.contentful.com',
          accessToken: previewAccessToken
        }
      )
      return previewClient
    }
    client = createClient(
      {
        host,
        space,
        accessToken,
        environment
      }
    )
    return client
  } catch (e) {
    throw new Error(
      {
        ...e,
        host,
        space,
        accessToken,
        environment
      }
    )
  }
}
